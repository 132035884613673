<template>

    <div id="welcome">

        <h2 class="brown-text">Welcome!</h2>

        <div class="content">

            <div class="text">

                <p class="brown-text">Opened in 2022, <span class="bold">Reena’s Jade Massage Therapy</span> is located in Midland, Michigan.</p>

                <p class="brown-text">This is where you will find Reena and the opportunity she provides to heal, relax, and experience relief from pain and stress. <span class="bold">Reena’s Jade Massage Therapy</span> offers therapeutic massage using many massage modalities, including Swedish massage, Deep Tissue massage, Ashiatsu DeepFeet Bar Therapy, Chinese foot massage and reflexology.</p>

                <p class="brown-text">We welcome you to join Reena to experience massage therapy that is customized to your needs. Reena can guide you as you select the type of massage you prefer, enhance your healing with massage oils, and provide exceptional professional massage therapy technique and expertise.</p>

                <p class="brown-text">Reena welcomes you to partner with her to reach your health and wellness goals.</p>

                <div class="buttons-container">

                    <div class="button green-button" @click="goToBooking()">Book now</div>

                    <div class="button brown-button" @click="goTo('contact')">Inquire</div>


                </div>

            </div>

            <div class="calendar">

                <Calendar />

            </div>

        </div>





    </div>



</template>

<script>
import Calendar from '@/components/Calendar.vue'

export default {
    name: 'Welcome',
    components: {
        Calendar
    },
    methods: {
        goToBooking() {
            this.$router.replace('/book')
        },
        goTo(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        }
    }
}


</script>

<style scoped>

#welcome {
    background-color: white;
    margin: auto;
    width: 100%;
    max-width: 1200px;
    margin-top: -10px;
    box-sizing: border-box;
    padding: 20px 20px 100px 20px;

}

h2 {
    font-size: 50px;
    font-weight: 200;
    margin: 0px 0px 10px 0px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.text {
    width: 60%;
    font-size: 17.5px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0px 100px 0px 0px;
}

.text p {
    width: 100%;
}

.calendar {
    width: 40%;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.button {
    text-transform: uppercase;
    font-weight: 600;
    align-items: center;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    align-self: center;
    font-size: 13px;
    letter-spacing: 1px;
    width: max-content;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
    margin-right: 50px;
}

.green-button {
    border: 1px solid #1E9656;
    background-color: #1E9656;
    color: white;
}

.green-button:hover {
    opacity: 0.8;
    transition: 0.2s; 
}

.brown-button {
    border: 1px solid #817263;
    background-color: #817263;
    color: white;
}

.brown-button:hover {
    opacity: 0.8;
    transition: 0.2s;
}


@media(max-width: 750px) { 

    #welcome {
        padding: 20px 20px 50px 20px;
    }

    .content {
        flex-direction: column;
    } 

    .calendar {
        width: 100%;
    } 

    .text {
        width: 100%;
        padding: 0px;
        margin-bottom: 20px;
    }

    .content p {
        width: 100%;
        padding: 0px 0px 0px 0px;
        margin-bottom: 15px;
    }

    .buttons-container {
        margin-bottom: 100px;
    }
}


</style>