<template>

    <div id="contact-form">

        <transition name="fade">

            <div class="modal submitting" v-if="sending === true">

                <h1>Sending message...</h1>

            </div>

        </transition>

        <transition name="fade">

            <div class="modal submitted" v-if="sent_successfully === true">

                <h1>Message sent successfully</h1>

                <p>We'll get back to you as soon as we can.</p>

                <div class="submit ok green-submit" @click="sent_successfully = false">Ok</div>
                
            </div>

        </transition>
        

        <h3 class="light-green-text">Leave a message</h3>

        <p class="error">{{ error }}</p>

        <form ref="form">

            <div class="form-field">

                <label class="brown-text">Name</label>

                <input v-model="name" name="name" required />

            </div>

            <div class="form-field">

                <label class="brown-text">Email</label>

                <input v-model="email" name="email" @keyup="checkEmailForValidity()" required />

            </div>

            <div class="form-field">

                <label class="brown-text">Message</label>

                <textarea class="large-input" v-model="message" name="message" required></textarea>

            </div>

            <div class="submit" :class="{ 'green-submit' : name != '' && validEmail() && message != '' }" @click="sendEmail()">Send Message</div>

        </form>



    </div>




</template>


<script>
import emailjs from 'emailjs-com';


export default {
    name: 'ContactForm',
    data () {
        return {
            name: '',
            email: '',
            message: '',
            error: '',
            sending: false,
            sent_successfully: false,
            failed_to_send: false
        }
    },
    methods: {
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        checkEmailForValidity() {
            if (this.validEmail() === false) {
                this.error = 'Please enter a valid email address'
            } else {
                this.error = ''
            }
        },
        sendEmail() {
            if (this.name != '' && this.validEmail() && this.message != '') {
                this.sending = true
                emailjs.sendForm('service_zvdhcxe', 'template_k7pbxg3', this.$refs.form, 'nwN1lD6JxHEotXGsF')
                .then((result) => {
                    this.sending = false 
                    this.sent_successfully = true
                    this.email = ''
                    this.message = ''
                    this.name = ''
                    this.error = ''
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
            }
        }
    }
}




</script>

<style scoped>

#contact-form {
    margin-top: 15px;
}

#contact-form h3 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;
}

</style>