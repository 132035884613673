<template>

    <div id="mobile-nav">

        <div class="mobile-nav-item light-green-text caps" @click="goHome()">

            <p>Home</p>

        </div>

        <div class="mobile-nav-item light-green-text caps" @click="goTo('services')">

            <p>Services</p>

        </div>
        
        <div class="mobile-nav-item light-green-text caps" @click="goTo('about')">

            <p>About</p>

        </div>

        <div class="mobile-nav-item light-green-text caps" @click="goTo('contact')">

            <p>Contact</p>

        </div>

        <div class="mobile-nav-item brown-text margin">

            <img src="@/assets/icons/RJMT_location_brown.svg" />

            <p>SUITE 4, 318 S SAGINAW RD. MIDLAND, MI 48640</p>

        </div>

        <div class="mobile-nav-item brown-text">

            <img src="@/assets/icons/RJMT_telephone_brown.svg" />

            <p>(989) 948-0368</p>

        </div>

        <div class="mobile-nav-item light-green-text" @click="goToBooking()">

            <img src="@/assets/icons/RJMT_heart_green.svg" />

            <p>BOOK NOW</p>

        </div>

    </div>



</template>

<script>

export default {
    name: 'MobileNav',
    methods: {
        goTo(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                this.$emit('close-mobile-nav')
            });
        },
        goHome() {
            this.$router.replace('/')
            this.$emit('close-mobile-nav')
        },
        goToBooking() {
            this.$router.replace('/book')
            this.$emit('close-mobile-nav')
        }
    }
}



</script>

<style scoped>

#mobile-nav {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;
    padding: 10%;
    font-weight: 600;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    height: 100vh;
}

#mobile-nav img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.mobile-nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: max-content;
    max-width: 200px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.mobile-nav-item:hover {
    border-bottom: 1px dotted #137444;
    transition: 0.2s;
}

.margin {
    margin-top: 25px;
}


</style>