<template>

    <div id="book">

      <VagaroScript />

      <!-- <h1 class="light-green-text">Book a Session</h1> -->

      <!-- <BookingForm /> -->

    </div>


</template>

<script>
import BookingForm from '@/components/BookingForm.vue'
import VagaroScript from '@/components/VagaroScript.vue'

export default {
    name: 'Book',
    components: {
        BookingForm,
        VagaroScript
    },
    data() {
        return {

        }
    },
    created() {
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}



</script>


<style scoped>

#book {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    margin: auto;
    margin-top: 100px;  
    box-sizing: border-box;
    padding: 75px 10% 100px 10%;
    background-color: #C5E0AC;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#book h1 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;
}

.massage-book-container {
    width: 100%;
    display: flex;
    margin-top: 100px;
    justify-content: center;
}

.vagaro a {
  font-size:14px; color:#AAA; 
  text-decoration:none;
}

@media(max-width: 750px) {
    #book {
        margin-top: 0px;
        width: 100%;
        padding: 75px 0px 0px 0px;
    }
}

@media(max-width: 600px) {
    #book {
        /* padding: 75px 10px 50px 10px; */
    }
}

</style>