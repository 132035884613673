<template>

    <div id="footer" class="dark-green">

        <p>© 2021 Reena’s Jade Massage Therapy. All rights reserved.</p>

    </div>




</template>


<script>

export default {
    name: 'Footer'
}



</script>

<style scoped>

#footer {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    height: 50px;
}




</style>