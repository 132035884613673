const schedule = [
    {
        day: "Monday",
        times: [
            {
                start: "9:00",
                end: "11:00"
            },
            {
                start: "13:00",
                end: "17:00"
            }
        ]
    },
    {
        day: "Tuesday",
        times: [
            {
                start: "9:00",
                end: "12:00"
            },
            {
                start: "13:00",
                end: "17:00"
            },
            {
                start: "18:00",
                end: "20:00"
            }
        ]
    },
    {
        day: "Wednesday",
        times: [
            {
                start: "9:00",
                end: "12:00"
            },
            {
                start: "13:00",
                end: "17:00"
            },
            {
                start: "18:00",
                end: "20:00"
            }
        ]
    }



]


export { schedule }