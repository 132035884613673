<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',

  components : {
    Navbar,
    Footer
  }
}



</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600;700&family=Source+Sans+Pro:wght@200;300;400;700&display=swap');

#app {
  /* font-family: 'Cormorant Garamond', serif; */
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  
}

html {
  min-height: 100%;
  background-color: #F7F7F7;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100%;
}

.banner-image {
    width: 100%;
    margin-bottom: 0px;
}

.light-green {
  background-color: #1E9656;
}

.light-green-text {
  color: #1E9656;
}

.dark-green {
  background-color: #137444;
}

.dark-green-text {
  color: #137444;
}

.brown {
  background-color: #817263;
}

.brown-text {
  color: #817263;
}

.white-text {
  color: white;
}

.gray-text {
  color: #807D7C;
}

.dark-gray-text {
  color: #413C3A;
}

.caps {
  text-transform: uppercase;
}

.bold {
  font-weight: 600;
}


/* css transitions */
.slide-fade-enter-active {
  transition: all .3s ease;
}

/* .slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
} */

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 30px 0px;
}

label {
    font-family: 'Cormorant Garamond', serif;
    font-size: 20px;
    margin-right: 10px;
    width: 100px;
}

input {
    display: flex;
    flex-grow: 1;
    border: 1px solid rgba(97, 177, 103, 0.5);
    border-radius: 5px;
    min-height: 50px;
    font-size: 17.5px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    font-family: 'Source Sans Pro', sans-serif;
}

.large-input {
    height: 200px;
}

textarea {
    border: 1px solid #61B167;
    padding: 5px 10px 5px 10px;
    font-family: 'Source Sans Pro', sans-serif;
    resize: none;
    display: flex;
    flex-grow: 1;
    font-size: 17.5px;
}

.error {
    background-color: rgb(255, 250, 240);
    border: 1px solid rgb(251, 219, 168);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    /* position: fixed; */
    top: 200px;
    left: 50px;
    transition: 0.2s;
    margin: 0px 0px 0px 0px;
    transition: 0.5s;
}

.submit {
    background-color: lightgray;
    /* background-color: #70B976; */
    color: white;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    width: max-content;
    border-radius: 5px;
    margin-left: auto;
    margin-top: 20px;
}

.submit-full-width {
  margin: auto;
  width: 100%;
}

.ok {
  margin-left: auto;
  margin-right: auto;
}

.ok:hover {
  opacity: 0.7;
  transition: 0.2s;
}

.green-submit {
    background-color: #70B976;
    transition: 0.2s;
}

.modal {
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

/* loader styles */
.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #137444;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media(max-width: 600px) {
  .form-field {
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 0px 40px 0px;
  }

  input {
    width: 100%;
  }

  label {
    width: 100%;
    margin-bottom: 10px;
  }

  textarea {
    width: 95%;
  }
}


</style>
