<template>

    <div id="choose-therapist">


        <p class="header brown-text">Choose your therapist</p>

        <div class="therapists">

            <div v-for="therapist in therapists" :key="therapist.last_name" class="therapist" @click="chooseTherapist(therapist.first_name)" :class="{ 'selected' : therapist.first_name === selected_therapist }">

                <img :src="require(`@/assets/images/${therapist.imgUrl}`)" class="therapist-thumb" />

                <div class="therapist-text">
                    
                    <p class="first-name">{{ therapist.first_name }}</p>

                    <p class="last-name">{{ therapist.last_name }}</p>

                </div>

            </div>

        </div>


    </div>



</template>


<script>

export default {
    name: 'ChooseTherapist',
    props: ['selected_therapist'],
    data () {
        return {
            therapists: [
                { 
                    first_name: "Reena",
                    last_name: "Ren Zhihong",
                    imgUrl: "reena_thumb.jpg"
                }
            ]
        }
    },
    created() {
        this.chooseTherapist('Reena')
    },
    methods: {
        chooseTherapist(therapist_first_name) {
            this.$emit('choose-therapist', therapist_first_name)
        }
    }
}


</script>

<style scoped>

#choose-therapist {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px 0px 50px 0px;
}

.header {
    font-size: 25px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    margin: 0px 0px 10px 0px;
}

.therapists {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.therapist {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1F1F2;
    box-sizing: border-box;
    padding: 20px 10px 20px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.therapist-thumb {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
}

.first-name {
    font-weight: 600;
    margin: 2.5px 0px 2.5px 0px;
    font-size: 20px;
}

.last-name {
    margin: 0px 0px 0px 0px;
}

.selected {
    background-color: #61B167;
    color: white;
}

@media(max-width: 900px) {
    #choose-therapist {
        width: 100%;
    }
}



</style>