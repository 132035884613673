<template>


    <div>

        <!-- {{ calendar_data }} -->

    </div>




</template>



<script>
import moment from 'moment'

export default {
    name: 'UnavailableTimes',
    props: ['date', 'selected_therapist', 'selected_duration', 'duration_integer'],
    data () {
        return {
            calendar_data: [],
            blocked_times: [],
            unavailable_times: [],
            duration: Number,
            final_unavailable_times: []
        }
    },
    created() {
        this.getCalendar();
    },
    methods: {
        getCalendar() {
            // var api_key = "AIzaSyB_WhBZMpaA0KiWpv_bo7Vp0USxAoQgKeY"
            var api_key = "AIzaSyDcql1wkPRFGggoy95SW0hAS9_nMmskDOM"
            if (this.selected_therapist === "Reena") {
                // var cal_id = "book@rjmt.care"
                var cal_id = "c_3f5k4dusldlb0n1kkhpdftlj48@group.calendar.google.com"
                var url = "https://www.googleapis.com/calendar/v3/calendars/" + cal_id + "/events?key=" + api_key
                this.$http.get(url).then((response) => {
                    this.calendar_data = response.data
                    this.processCalendarData();
                })
            }
            // if (this.selected_therapist === "Keegan") {
            //     var cal_id = "a7sh93vb1g0t9s2qoo7t8gal84@group.calendar.google.com"
            //     var url = "https://www.googleapis.com/calendar/v3/calendars/" + cal_id + "/events?key=" + api_key
            //     this.$http.get(url).then((response) => {
            //         console.log(response)
            //         this.calendar_data = response.data
            //         this.processCalendarData();
            //     })               
            // }
        },
        processCalendarData() {
            var calendar_data = this.calendar_data.items
            console.log(`BLOCKED TIMES: ${JSON.stringify(calendar_data)}`)
            var blocked_times = []
            var tz = 'America/New_York'
            var key = 0
            for (let i = 0; i < calendar_data.length; i++) {
                var blocked_time = {}
                blocked_time.date = moment(calendar_data[i].start.dateTime).tz("America/New_York").format('YYYY-MM-DD')
                blocked_time.start = moment(calendar_data[i].start.dateTime).tz("America/New_York").format('hh:mm A')
                blocked_time.end = moment(calendar_data[i].end.dateTime).tz("America/New_York").format('hh:mm A')
                blocked_time.key = key
                blocked_times.push(blocked_time)
                key++
            };
            
            this.blocked_times = blocked_times

            // console.log(`BLOCKED TIMES: ${JSON.stringify(this.blocked_times)}`)
            
            this.blockedTimesThatDay()
        },
        blockedTimesThatDay() {
            var parsed_date = moment(this.date).tz("America/New_York").format('YYYY-MM-DD')
            var blocked_times = this.blocked_times
            var blocked_times_on_this_date = []
            for (let i = 0; i < blocked_times.length; i++) {
                if (blocked_times[i].date === parsed_date) {
                    var blockedObject = {}
                    blockedObject.startTime = this.timeToMinutes(String(moment(blocked_times[i].start, 'h:m A').format('HH:mm')))
                    blockedObject.endTime = this.timeToMinutes(String(moment(blocked_times[i].end, 'h:m A').format('HH:mm')))
                    blocked_times_on_this_date.push(blockedObject)
                }
            }
            this.unavailable_times = blocked_times_on_this_date
            
            // this.$emit('blocked-times', blocked_times_on_this_date);
            this.createNewTimesArray(this.unavailable_times)
            this.createBlockedTimesArrayRegardlessOfBookings(this.unavailable_times)
            
        },
        createBlockedTimesArrayRegardlessOfBookings(times) {
            var interval = 1

            var times_open = times

            var new_array = []

            for (let i = 0; i < times_open.length; i++) {
                
                var start = times_open[i].startTime

                for (let t = start; t < 1440; t++) {

                    if (t >= start && t <= times_open[i].endTime) {

                        new_array.push(t)

                        t = t + (interval - 1)

                    }

                }

            }

            // console.log(`BLOCKED TIMES: ${new_array}`)

            this.$emit('get-unavailable-times-regardless-of-bookings', new_array)
        },
        createNewTimesArray(times) {

            var interval = 1

            var times_open = times

            var new_array = []

            for (let i = 0; i < times_open.length; i++) {
                
                var start = times_open[i].startTime

                for (let t = start; t < 1440; t++) {

                    if (t >= start && t <= times_open[i].endTime) {

                        new_array.push(t)

                        new_array.push(t - this.duration_integer)

                        t = t + (interval - 1)

                    }

                }

            }

            this.final_unavailable_times = new_array
            this.$emit('unavailable_times_in_minutes', this.final_unavailable_times)
            this.componentKey++

        },


       timeToMinutes(time) {
            var h = time.split(':')[0];
            var m = time.split(':')[1];
            return (Number(h*60) + Number(m))
        }


    }
    // watch: {
    //     final_unavailable_times() {
    //         this.$emit('unavailable_times_in_minutes', this.final_unavailable_times)
    //         this.componentKey++
    //     }
    // }
}




</script>


<style scoped>



</style>

