<template>

    <div id="home">

        <Banner />

        <Welcome />

        <Services ref="services" :treatments="treatments" />

        <About />

        <Contact />

    </div>



</template>


<script>
import Banner from '@/components/Banner.vue'
import Welcome from '@/components/Welcome.vue'
import Services from '@/components/Services.vue'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'


export default {
    name: 'Home',
    metaInfo: {
        title: "Reena's Jade Massage Therapy - Your Personal Health & Wellness Care Provider in Midland, Michigan",
        titleTemplate: null,
        meta: [
            {
                name: 'description',
                content: "Reena's Jade Massage Therapy – Midland, Michigan massage expert",
                keywords: 'massage therapy midland mi, swedish massage midland, reflexology midland mi, deepfeet bar therapy michigan'
            }
        ],
        htmlAttrs: {
        lang: 'en',
        amp: true
        }
    },
    components: {
        Banner,
        Welcome,
        Services,
        About,
        Contact
    },
    data() {
        return {
            treatments: []
        }
    },
    created() {
        this.getServices()
    },
    methods: {
        getServices() {
            var url = `https://api.npoint.io/5993f75f50471134da12`      
            this.$http.get(url).then((response) => {
                this.treatments = response.data
            })
        }
    }
}




</script>

<style scoped>

</style>