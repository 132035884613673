<template>

    <div id="about">

        <h1 class="light-green-text">About</h1>

        <div class="content">

            <div class="bio">

                <div class="bio-top">
                    
                    <img :src="require(`@/assets/images/${bios[counter].imgUrl}`)" />

                    <div class="name brown-text">

                        <div class="filler"></div>

                        <p class="first-name">
                            {{ bios[counter].first_name }}
                        </p>

                        <p class="last-name">
                            {{ bios[counter].last_name }}
                        </p>

                        <div class="filler"></div>

                    </div>

                </div>

                <div v-for="paragraph in bios[counter].paragraphs" :key="paragraph" class="paragraph brown-text">
                    <p v-html="paragraph"></p>
                </div>

            </div>



        </div>

    </div>



</template>

<script>

export default {
    name: 'About',
    data() {
        return {
            counter: 0,
            bios: [
                {
                first_name: "Reena",
                last_name: "Ren Zhihong",
                imgUrl: "reena_pp_jade_sq.jpeg", 
                paragraphs: [
                  "<span style='font-weight: 600'>Ms. Ren</span> offers a unique holistic approach, as an elite practitioner of massage and stretch therapy. Given that each individual has unique characteristics and needs, Reena, after consultation and assessment, uses a myriad of techniques to promote wellness.",
                  "Credentialed as a licensed massage therapist by the state of Michigan, she has since trained in numerous prominent massage techniques from various parts of the world. She expanded her mastery further into the craft of <span style='font-weight: 600'>Ashiatsu DeepFeet Bar Therapy</span> and earned the title <span style='font-weight: 600'>Master DeepFeet Therapist</span> in June 2023. To date, she remains the only therapist to have achieved this certification in the whole state of Michigan.",
                  "Ms. Ren matriculated at Delta College, before commencing her in-service training at <span style='font-weight: 600'>Naturopathic Institute of Therapies and Education (NITE)</span> in Mt. Pleasant MI, <span style='font-weight: 600'>LMT Success Group</span> in Las Vegas NV, and <span style='font-weight: 600'>Living Sabai School of Thai Massage</span>, where she is receiving continuous training in Ashville, NC. In addition, internationally, Reena completed advanced training at the <span style='font-weight: 600'>Bali International Spa Academy (BISA)</span> in Bali, Indonesia.",
                  "Reena emigrated from China in order to enhance her massage therapy training and pursue opportunities afforded in the U.S. She has enjoyed living in welcoming <span style='font-weight: 600'>Midland, Michigan since 2014</span>.",
                  "Her studio is in a quiet residential area; 1709 Ardmore Street in Midland. Close to the <span style='font-weight: 600'>tennis center</span>, by design, she employs a wicked <span style='font-weight: 600'>topspin forehand (with a grin)</span>. Reveres travel, both in the U.S., as well as abroad. She enjoys her <span style='font-weight: 600'>Labradoodle Izaak</span>, who may be part of your greeting.",
                  "Reena is committed to advanced Thai massage training in <span style='font-weight: 600'>Bangkok, January 2025</span>.",
                  "Reena's knowledge and assessment skills are invaluable tools in determining client needs. She welcomes each person as a partner in the mutual pursuit of wellness. <span style='font-weight: 600'>Ask about the mystical properties of the Buddhist singing bowl which she utilizes in many treatments!</span>"
                ]
              }
            ]
        }
    },
    methods: {
        increaseCounter() {
            if (this.counter < 1) {
                this.counter++
            } else {
                this.counter = 0
            }
        }
    }
}


</script>

<style scoped>

#about {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;
}

#about h1 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;
}

.content {
    width: 90%;
    margin: auto;
    max-width: 600px;
}

p {
    font-size: 17.5px;
    line-height: 40px;
}

.bio-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.name {
    display: flex;
    flex-direction: column;
    font-family: 'Cormorant Garamond', serif;
    width: 45%;
    height: 100vh;
    max-height: 261px;
}

.first-name {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 800;
    margin: 0px 0px 0px 0px;
}

.last-name {
    font-size: 25px;
    margin: 0px 0px 0px 0px;
}

.bio-top img {
    width: 45%;
    height: auto;
    margin-right: 5%;
    max-height: 261px;
    max-width: 261px;
}

.next-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    margin-top: auto;
    height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}

.next-button img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.filler {
    flex-grow: 1;
    min-height: 50px;
}

.reversed {
    transform: rotate(180deg);
}

@media(max-width: 600px) {
    .name {
        max-height: 180px;
    }
}


</style>