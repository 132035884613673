<template>

    <div id="services">

        <h1 class="light-green-text">Services</h1>

        <div class="service" v-for="service in parsedTreatments()" :key="service.name" >

            <h3 class="service-header brown-text"> {{ service.name }} </h3>

            <p class="service-description dark-gray-text"  v-for="description in service.description" :key="description[0]">
                {{ description }}
            </p>

            <div class="price-container">

                <p class="service-price dark-gray-text price-text" v-for="duration in service.durations" :key="duration.price">

                    {{ duration.duration }} {{ duration.price }}

                </p>

            </div>


            <div class="filler"></div>

            <div class="button" :class="getClass(service.availability)" @click="bookTreatment(service)">{{ service.availability }}</div>

            <span class="bottom-rail wide" v-if="parsedTreatments().indexOf(service) < 8"></span>

            <span class="bottom-rail narrow" v-if="parsedTreatments().indexOf(service) < 9"></span>

            <span class="right-rail" v-if="parsedTreatments().indexOf(service) % 2 === 0"></span>

        </div>


    </div>



</template>

<script>
export default {
    name: 'Services',
    props: ['treatments'],
    methods: {
        getClass(avail) {
            const availability = avail;
            if (availability === 'Book now') {
                return 'green-button'
            } else if (availability === 'Coming soon') {
                return 'brown-button'
            } else if (availability === 'Inquire') {
                return 'solid-green-button'
            }
        },
        bookTreatment(service) {
            if (service.availability === 'Book now') {
                this.$store.commit('setTreatment', service)
                localStorage.setItem('treatment', JSON.stringify(service));
                this.$router.replace('/book')
            }
            if (service.availability === 'Inquire') {
                this.goTo('contact')
            }
        },
        goTo(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        },
        parsedTreatments() {
            const availableTreatments = []
            for (let i = 0; i < this.treatments.length; i++) {
                if (this.treatments[i].combo === false) {
                    availableTreatments.push(this.treatments[i])
                }
            }
            return availableTreatments            
        }
    }
}


</script>

<style scoped>

#services { 
    background-color: #D3E8C1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 50px;
}

#services h1 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;
}

.service {
    width: 50%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px;
    position: relative;
}

.bottom-rail {
    position: absolute;
    width: 80%;
    bottom: 0;
    border-bottom: 1px solid #1E9656;
    left: 50%;
    transform: translate(-50%, 0);
}

.right-rail {
    position: absolute;
    height: 50%;
    right: 0;
    border-right: 1px solid #1E9656;
    top: 50%;
    transform: translate(0, -50%);
}

.service h3 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px 0px 10px 0px;
    width: 100%;
    letter-spacing: 1px;
    padding: 25px;
}

.service p {
    text-align: center;
    line-height: 25px;
}

.border-right {
    border-right: 1px solid #1E9656;
    height: 100%;
}

.service-description {
    box-sizing: border-box;
    margin: 0px 0px 0px 0px;
}

.filler {
    flex-grow: 1;
}

.button {
    text-transform: uppercase;
    font-weight: 600;
    align-items: center;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    align-self: center;
    font-size: 13px;
    letter-spacing: 1px;
    width: max-content;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
}

.green-button {
    border: 1px solid #1E9656;
    color: #1E9656;
}

.green-button:hover {
    background-color: #1E9656;
    color: white;  
    transition: 0.2s; 
}

.brown-button {
    border: 1px solid #817263;
    color: #817263;
}

.brown-button:hover {
    background-color: #817263;
    color: white;
    transition: 0.2s;
}

.solid-green-button {
    background-color: #1E9656;
    color: white;
}

.solid-green-button:hover {
    background-color: #137444;
    color: white;  
    transition: 0.2s; 
}

.narrow {
    display: none;
}

.price-container {
    margin-top: 50px;
}


.price-text {
    margin: 0px 0px 0px 0px;
}

@media(max-width: 800px) {
    .service {
        width: 100%;
    }

    .narrow {
        display: flex;
    }

    .wide {
        display: none;
    }

    .right-rail {
        display: none;
    }
}

</style>