<template>

    <div id="calendar">

        <div v-if="is_loading === true">

            <div class="loader-container">

                <div class="lds-ripple"><div></div><div></div></div>

            </div>

        </div>

        <div v-else>

            <div class="calendar-top">

                <div class="day dark-green white-text">

                    <p>Day</p>

                </div>

                <div class="time dark-green white-text">

                    <p>Time</p>

                </div>

            </div>

            <div class="day-of-week" v-for="day in times" :key="day.day">

                <div class="date-info gray-text">

                    <p>{{ day.dayOfWeek }}</p>

                </div>

                <div class="time-info gray-text">

                    <p v-for="time in day.times" :key="time">
                        {{ time }}
                    </p>

                </div>

            </div>

        </div>

    </div>


</template>


<script>

export default {
    name: 'Calendar',
    data () {
        return {
            api_data: [],
            schedule: [],
            days: [],
            times: [],
            schedule_array: [],
            is_loading: true,
            days_hardcoded: [ 
                    {day: 'Monday', times: ['9:00 AM – 11:00 AM', '01:00 PM - 05:00 PM'] }, 
                    {day: 'Tuesday', times: ['09:00 AM - 12:00 PM', '01:00 PM - 05:00 PM', '06:00 PM - 08:00 PM'] }, 
                    {day: 'Wednesday', times: ['09:00 AM - 11:00 PM', '01:00 PM - 05:00 PM', '06:00 PM - 08:00 PM'] }, 
                    {day: 'Thursday', times: ['09:00 AM - 12:00 PM', '01:00 PM - 05:00 PM', '06:00 PM - 08:00 PM'] }, 
                    {day: 'Friday', times: ['09:00 AM - 12:00 PM', '01:00 PM - 05:00 PM'] }, 
                    {day: 'Saturday', times: ['By appointment'] }, 
                    {day: 'Sunday', times: ['By appointment'] }, 
                ]
        }
    },
    created() {
        this.getSchedule();
    },
    methods: {
        getSchedule() {
            var spreadSheetId = '14dCZLrMeeu16har8l4DWh3FcMbCsU9WauOjxl-ePxO4'
            var url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}/?key=AIzaSyB_WhBZMpaA0KiWpv_bo7Vp0USxAoQgKeY&includeGridData=true`;   
            this.$http.get(url).then((response) => {
                this.api_data = response.data.sheets[0].data[0].rowData
                this.parseSpreadsheetData()
                this.is_loading = false;
            })
        },
        parseSpreadsheetData() {
            var spreadsheetData = this.api_data
            const days_array = []
            const times_array = []
            const finalArray = []
            for (let i = 0; i < spreadsheetData.length; i++) {
                var dailyData = spreadsheetData[i].values
                days_array.push(dailyData[0].userEnteredValue.stringValue)
                var dailyDataParsed = {}
                var dailyDataTimes = []
                for (let i = 0; i < dailyData.length; i++) {
                    if (i === 0) {
                        dailyDataParsed.dayOfWeek = dailyData[i].userEnteredValue.stringValue
                    } else {
                        dailyDataTimes.push(dailyData[i].userEnteredValue.stringValue)
                    }
                    dailyDataParsed.times = dailyDataTimes
                }
                times_array.push(dailyDataParsed)
            }
            this.times = times_array
        }
    }
}



</script>

<style scoped>

.loader-container {
    height: 500px;
}

.calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.calendar-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    font-family: 'Cormorant Garamond', serif;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
}

.calendar-top p {
    margin: 0px;
}

.day {
    width: 30%;
    border-radius: 5px 0px 0px 0px;
    padding: 5px 0px 5px 0px;
    border-right: 1px solid #1E9656;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time {
    width: 70%;
    border-radius: 0px 5px 0px 0px;
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day-of-week {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #1E9656;
    border-left: 1px solid #807D7C;
    border-right: 1px solid #807D7C;
}

.day-of-week:last-child {
    border-bottom: 1px solid #807D7C;
}

.day-of-week p {
    width: max-content;
    margin: 0px 0px 0px 0px;
    /* margin: 2.5px 0px 2.5px 0px; */
    letter-spacing: 1px;
}

.date-info {
    text-transform: uppercase;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #1E9656;
    height: 70px;

}

.time-info {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rows {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    margin: 100px 0px 100px 0px;
    border: 1px dotted lightgray;
}

.days-container-thing {
    margin-bottom: 100px;
}

@media(max-width: 1000px) {
    .day-of-week p {
        font-size: 13px;
    }
}


</style>