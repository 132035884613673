<template>

    <div id="banner" @click="goToOffers()">

        <img class="banner-image" src="@/assets/images/banner.png" />

    </div>



</template>

<script>

export default {
    name: 'Banner',
    methods: {
        goToOffers() {
            this.$router.replace('/offers')
        }
    }
}


</script>

<style scoped>

#banner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    z-index: 1;
    margin-top: 100px;
    box-sizing: border-box;
    height: auto;
    max-height: 1024px;
    cursor: pointer;
}

.banner-text-container {
    width: max-content;
    background-color: rgba(123, 106, 88, 0.7);
    position: relative;
    z-index: 2;
    font-family: 'Cormorant Garamond', serif;
    box-sizing: border-box;
    padding: 0px 0px 0px 20px;
    height: max-content;
    border-radius: 0px 20px 20px 0px;
}

h1 {
    margin: 0px 0px 0px 0px;
    font-size: 80px;
}

h2 {
    margin: 0px 0px 0px 0px;
    font-size: 80px;
}

.line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -20px;
}

.offers {
    display: flex;
    width: max-content;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 5px;
    margin-top: 30px;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
}

.offers:hover {
    background-color: #6AC183 !important;
    transition: 0.2s;
}

@media(max-width: 750px) {
    #banner {
        margin-top: 50px;
    }
}


@media(max-width: 700px) {
    h1 {
        font-size: 60px;
    }

    h2 {
        font-size: 60px;
    }
}


</style>