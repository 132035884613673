<template>

    <div id="nav">

        <div id="top-nav-info">

            <div class="top-nav half">

                <img src="@/assets/icons/RJMT_location_brown.svg" />

                <p>MIDLAND, MI 48642</p>

            </div>

            <div class="filler"></div>

            <div class="top-nav quarter">

                <img src="@/assets/icons/RJMT_telephone_brown.svg" />

                <p>(989) 948-0368</p>

            </div>

            <div class="top-nav quarter light-green-text small-icon" @click="goToBooking()">

                <img src="@/assets/icons/RJMT_heart_green.svg" />

                <p>BOOK NOW</p>

            </div>


        </div>

        <div id="nav-links">

            <div class="nav-link quarter light-green" @click="goHome()">

                <p>Home</p>

                <img class="toggle-nav-icon" @click="mobile_nav_toggled = !mobile_nav_toggled" src="@/assets/icons/nav-icon.svg" />

            </div>

            <div class="nav-link quarter dark-green" @click="goTo('services')">

                <p>Services</p>

            </div>

            <div class="filler dark-green"></div>

            <div class="nav-link quarter dark-green" @click="goTo('about')">

                <p>About</p>

            </div>

            <div class="nav-link quarter dark-green" @click="goTo('contact')">

                <p>Contact</p>

            </div>

            <div class="logo-container dark-green" @click="goHome()" :class="{ 'small-logo-container' : scrolled_from_top === true }">

                <img class="logo" src="@/assets/icons/rjmt_round.png" :class="{ 'small-logo' : scrolled_from_top === true }" />

            </div>

        </div>

        <!-- <transition name="fade"> -->

            <div class="mobile-nav-container" v-if="mobile_nav_toggled === true">

                <MobileNav v-if="mobile_nav_toggled === true" v-on:close-mobile-nav="closeNavbar()" />

            </div>

        <!-- </transition> -->



    </div>

</template>

<script>
import MobileNav from '@/components/MobileNav.vue'

export default {
    name: 'Navbar',
    components: {
        MobileNav
    },
    data() {
        return {
            mobile_nav_toggled: false,
            scrolled_from_top: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            var target = document.querySelector('#nav');
            if (window.scrollY >= target.offsetHeight + 30) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 30) {
                this.scrolled_from_top = false
            }
        },
        goTo(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        },
        closeNavbar() {
            this.mobile_nav_toggled = false
        },
        goHome() {
            this.$router.replace('/')
        },
        goToBooking() {
            this.$router.replace('/book')
        }
    }
}



</script>

<style scoped>

#nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding-top: 0px;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #F7F7F7;
}

#top-nav-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #817263;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    height: 50px;
    background-color: #F7F7F7;
    align-items: center;
}

#top-nav-info img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.small-icon {
    cursor: pointer;
}

.small-icon img {
    width: 10px !important;
    height: 10px !important;
    margin-top: 2.5px;
}

.half {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
}

.quarter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;   
    justify-content: center; 
}

.filler {
    display: flex;
    flex-grow: 1;
}

#nav-links {
    display: flex;
    flex-direction: row;
    font-family: 'Cormorant Garamond', serif;
    position: relative;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.nav-link {
    font-size: 15px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    box-sizing: border-box;
    padding: 10px 5px 10px 5px;
    cursor: pointer;
    height: 50px;
}

.nav-link p {
    margin: 0px 0px 0px 0px;
}

.nav-link:hover {
    background-color: #6AC183;
    transition: 0.2s;
}

.logo-container {
    width: 125px;
    height: 125px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    transition: 0.5s;
}

.small-logo-container {
    width: 60px;
    height: 60px;
    top: -5px;
    transition: 0.5s;
}

.logo-container:hover {
    background-color: #6AC183 !important;
    transition: 0.2s;
}

.logo {
    width: 115px;
    height: 115px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    /* top: 10px; */
}

.toggle-nav-icon {
    width: 30px;
    height: 30px;
}

.small-logo {
    width: 50px;
    height: 50px;
    transition: 0.5s;
}

@media(min-width: 750px) {
    .mobile-nav-container {
        display: none;
    }

    .toggle-nav-icon {
        display: none;
    }
}

@media(max-width: 750px) {
    #top-nav-info { 
        display: none;
    }

    .nav-link p {
        display: none;
    }

    .logo-container {
        width: 45px;
        height: 45px;
        top: 2.5px;
    }

    .logo {
        width: 35px;
        height: 35px;
    }

    .light-green {
        background-color: #137444;
    }

    .nav-link:hover {
        background-color: #137444;
    }

    #nav {
        background-color: #137444;
    }

    /* .nav-link:first-child:hover {
        background-color: #6AC183;
    } */
}


</style>