import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Book from '@/components/Book'
import Offers from '@/components/Offers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/book',
    name: 'Book',
    component: Book
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
