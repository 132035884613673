<template>


    <div id="booking-form">

        <transition name="fade">

            <div class="modal submitting" v-if="sending === true">

                <h1>Submitting booking...</h1>

            </div>

        </transition>

        <transition name="fade">

            <div class="modal submitted" v-if="sent_successfully === true">

                <img src="@/assets/icons/rjmt_round.png" class="modal-logo" />

                <h1>Booking request submitted!</h1>

                <p class="confirmation">Confirmation will be sent to your email inbox.</p>

                <p class="note">*IF YOU CANNOT FIND YOUR CONFIRMATION EMAIL AFTER 24 HOURS, PLEASE CHECK YOUR SPAM / JUNK FOLDER.</p>

                <div class="submit ok green-submit" @click="closeModal()">Ok</div>
                
            </div>

        </transition>

        <div class="error-container">

            <p class="error" v-if="error != ''">{{ error }}</p>

        </div>

        <form ref="form">

            <div class="form-field">

                <label class="brown-text">First name</label>

                <input v-model="first_name" required placeholder="Enter your first name" name="first_name" />

            </div>

            <div class="form-field">

                <label class="brown-text">Last name</label>

                <input v-model="last_name" required placeholder="Enter your last name" name="last_name" />

            </div>

            <div class="form-field">

                <label class="brown-text">Email</label>

                <input v-model="email" name="email" required placeholder="Enter your email address" @keyup="checkEmailForValidity()" />

            </div>

            <div class="form-field">

                <label class="brown-text">Mobile phone</label>

                <input v-model="mobile_phone" name="mobile_phone" required placeholder="Enter your phone number" />

            </div>

            <ChooseTherapist :selected_therapist="selected_therapist" v-on:choose-therapist="chooseTherapist" />

            <div class="form-field" v-if="selected_therapist != ''">

                <label class="brown-text">Treatment</label>

                <transition name="fade">

                <div class="selector-container treatments-container">

                        <span class="treatment" v-for="treatment_type in availableTreatments()" :key="treatment_type.name" :class="{ 'selected' : treatment_type.name == treatment.name, 'hidden' : (show_all_treatments === false && treatment_type.name != treatment.name) }" @click="selectTreatment(treatment_type)">
                            
                            <p>{{ treatment_type.name }}</p>
                            
                        </span>

                    <div class="show-all" @click="show_all_treatments = !show_all_treatments">
                        <p v-if="show_all_treatments === false">Show more</p>
                        <p v-if="show_all_treatments === true && Object.keys(treatment).length > 0">Show less</p>
                    </div>

                </div>

                </transition>


            </div>

            <input v-if="selected_duration.length > 0" v-model="selected_duration[0].duration" class="invisible" name="duration">

            <input v-model="treatment.name" class="invisible" name="treatment">

            <input v-model="selected_therapist" class="invisible" name="therapist">

            <input :value="readableDate()"  name="date" class="invisible">

            <input v-model="time" class="invisible" name="time">

            <div class="form-field" v-if="treatment != '' && selected_therapist != ''">

                <label class="brown-text">Duration</label>

                <div class="selector-container duration-selector">

                    <div class="duration" v-for="duration in treatment.durations" :key="duration.price" :class="{ 'selected' : selected_duration[0] === duration }" @click="selectDuration(duration)">
                        <p class="duration-time">{{ duration.duration }}</p>
                        <p class="duration-price">{{ duration.price }}</p>
                    </div>

                </div>

            </div>

            <div class="form-field" v-if="treatment != '' && selected_therapist != '' && selected_duration.length > 0">

                <label class="brown-text">Date</label>

                    <datepicker v-model="date" :inline="true" :placeholder="'Choose a date'" :disabled-dates="disabledDates" class="datepicker" :calendar-class="'calendar-wrapper'"></datepicker>

            </div>
            

            <transition name="fade">

                <div class="form-field" v-if="date != '' && selected_duration.length > 0">

                    <label class="brown-text">Time</label>

                    <UnavailableTimes :date="date" :key="componentKey" v-on:get-unavailable-times-regardless-of-bookings="getBlockedTimes" v-on:unavailable_times_in_minutes="getUnavailableTimes" :selected_therapist="selected_therapist" :duration_integer="duration_integer"  />

                    <div class="selector-container time-selector">

                        <div class="loader-container" v-if="is_loading === true">

                            <div class="lds-ripple"><div></div><div></div></div>

                        </div>

                        <!-- <div v-else class="time" v-for="timeOfDay in final_times_for_real" :key="timeOfDay" @click="selectTime(timeOfDay)" :class="{ 'selected' : time === getReadableTime(timeOfDay) }"> -->
                        <div v-else class="time" v-for="timeOfDay in filteredTimes" :key="timeOfDay" @click="selectTime(timeOfDay)" :class="{ 'selected' : time === getReadableTime(timeOfDay) }" >
                            
                            {{ getReadableTime(timeOfDay) }}
                            
                        </div>


                        <div v-if="filteredTimes.length === 0 && is_loading === false">

                            <p class="unavailable-text">
                                There are no appointment slots available for this therapist on this date.
                            </p>

                            <p class="unavailable-text unavailable-text-bold">
                                Please select a different date.
                            </p>

                        </div>

                    </div>

                </div>

            </transition>

            <div class="form-field" v-if="treatment != '' && selected_therapist != '' && selected_duration.length > 0">

                <label class="brown-text">Note <i>(optional)</i></label>

                <textarea class="large-input" v-model="note" name="note" required></textarea>

            </div>

            <div class="submit submit-full-width" :class="{ 'green-submit' : first_name != '' && last_name != '' && validEmail() && mobile_phone != '' && date != '' && selected_duration.length > 0 && time != '' }" @click="sendEmail()" v-if="treatment != '' && selected_therapist != '' && selected_duration.length > 0">Submit Booking</div>

        </form>

    </div>




</template>


<script>
import Datepicker from 'vuejs-datepicker';
import UnavailableTimes from '@/components/UnavailableTimes.vue'
import ChooseTherapist from '@/components/ChooseTherapist.vue'
import emailjs from 'emailjs-com';
import moment from 'moment'


export default {
    name: 'BookingForm',
    components: {
        Datepicker,
        UnavailableTimes,
        ChooseTherapist
    },
    data() {
        return {
            api_data: [],
            is_loading: false,
            first_name: '',
            last_name: '',
            email: '',
            error: '',
            mobile_phone: '',
            treatment: {},
            selected_duration: [],
            selected_therapist: '',
            date: '',
            readable_date: '',
            disabledDates: {},
            time: '',
            note: '',
            times_open: [],
            duration_integer: Number,
            show_all_treatments: false,
            treatments: [],
            sending: false,
            sent_successfully: false,
            failed_to_send: false,
            parsed_available_times: [],
            calendar_data: [],
            unavailable_times: [],
            times_open_array: [],
            new_times_open_array: [],
            final_times_open_array: [],
            final_times_unavailable_array: [],
            final_times_for_real: [],
            all_times_unavailable_for_that_day: false,
            componentKey: 0,
            blocked_times: [],
            conflicting_times: []
        }
    },
    created() {
        this.getStartDate();
        this.getTreatments();
    },
    methods: {
        chooseTherapist: function (therapist_first_name) {
            this.selected_therapist = therapist_first_name
            this.parseTreatments();
            this.getSchedule();
        },
        getBlockedTimes: function (blocked_times) {
            this.blocked_times = blocked_times
        },
        getConflictingTimes(time) {
            var duration = this.duration_integer
            var startTime = time
            var conflictingTime = []
            var i = startTime
            while (i < (startTime + duration)) {
                if (this.blocked_times.includes(i))
                conflictingTime.push(i)
                i = i + 15
            }
            return conflictingTime
        },
        getNumberRange(start, end) {
            return new Array(end - start).fill().map((d, i) => i + start);
        },
        getAllTimesWithConflicts(final_times) {
            var conflictingTimes = []
            for (let i = 0; i < final_times.length; i++) {
                if (this.getConflictingTimes(final_times[i]).length > 0) {
                    conflictingTimes.push(final_times[i])
                }
            }
            this.conflicting_times = conflictingTimes
        },
        getUnavailableTimes (value) {
            this.final_times_unavailable_array = value.sort(function (a, b) {  return a - b;  })
            var available_times = this.final_times_open_array
            var final_times = []
            for (let i = 0; i < available_times.length; i++) {
                if (!this.final_times_unavailable_array.includes(available_times[i])) {
                    final_times.push(available_times[i])
                }
            }
            // this.getAllTimesWithConflicts(final_times)
            this.final_times_for_real = final_times
            if (this.final_times_for_real.length === 0) {
                this.all_times_unavailable_for_that_day = true
            } else {
                this.all_times_unavailable_for_that_day = false
            }
            
            this.is_loading = false
        },
        getReadableTime(time) {
            var date = new Date(null);
            date.setSeconds(time * 60); // specify value for SECONDS here
            return moment(date.toISOString().substr(11, 8), 'hh:mm:ss').format('hh:mm A')
        },
        timeToMinutes(time) {
            var h = time.split(':')[0];
            var m = time.split(':')[1];
            return (Number(h*60) + Number(m))
        },
        
        convertTimesToArray(times) {

            var availableTimes = []
            const dayOfWeek = (String(this.date).substring(0,3))
            const allAvailableTimes = this.times_open

            for (let i = 0; i < allAvailableTimes.length; i++) {
                if (String(allAvailableTimes[i].dayOfWeek).substring(0,3) === dayOfWeek) {
                    availableTimes.push(allAvailableTimes[i].times)
                }
            }

            this.times_open_array = availableTimes[0]

            const converted_array = []

            for (let i = 0; i < this.times_open_array.length; i++) {
                
                var timesObject = {}

                const split_times = this.times_open_array[i].split(" - ");

                timesObject.startTime = this.timeToMinutes(String(moment(split_times[0], 'h:m A').format('HH:mm')))

                timesObject.endTime = this.timeToMinutes(String(moment(split_times[1], 'h:m A').format('HH:mm')))

                converted_array.push(timesObject)

            }

            this.new_times_open_array = converted_array       

            this.createNewTimesArray(this.new_times_open_array)

        },
        
        createNewTimesArray(times) {

            var interval = 15

            var times_open = times

            var new_array = []

            for (let i = 0; i < times_open.length; i++) {
                
                var start = times_open[i].startTime

                for (let t = start; t < 1440; t++) {

                    if (t >= start && t <= (times_open[i].endTime - this.duration_integer)) {

                        new_array.push(t)

                        t = t + (interval - 1)

                    }

                }

            }

            this.final_times_open_array = new_array

        },
        getStartDate() {
            var date = new Date();
            date = date.setDate(date.getDate() + 1);
        },
        getTreatments() {
            var url = `https://api.npoint.io/5993f75f50471134da12`      
            this.$http.get(url).then((response) => {
                this.treatments = response.data
            })
        },
        parseTreatments() {
            var allTreatments = this.treatments
            var therapistSpecificTreatments = []
            for (let i = 0; i < allTreatments.length; i++) {
                if (allTreatments[i].therapists.includes(this.selected_therapist)) {
                    therapistSpecificTreatments.push(allTreatments[i])
                }
            }
            this.treatments = therapistSpecificTreatments
            this.getSelectedTreatment();
        },
        getSelectedTreatment() {
            if (localStorage.treatment == undefined) {
                this.show_all_treatments = true
            } else {
                this.treatment = this.$store.getters.getSelectedTreatment
                this.treatment = JSON.parse(localStorage.treatment)
            }
        },
        closeModal() {
            this.sent_successfully = false
            this.$router.replace('/')
        },
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        checkEmailForValidity() {
            if (this.validEmail() === false) {
                this.error = 'Please enter a valid email address'
            } else {
                this.error = ''
            }
        },
        selectTreatment(treatment) {
            this.selected_duration = []
            this.treatment = treatment
            localStorage.setItem('treatment', JSON.stringify(treatment));
            this.$store.commit('setSelectedTreatment', treatment)
        },
        selectDuration(duration) {
            this.selected_duration = []
            this.selected_duration.push(duration)
            this.getDurationInteger();
        },
        getDurationInteger() {
            var duration = this.selected_duration[0].duration
            this.duration_integer = Number(duration.substring(0, 3))
        },
        selectTime(timeOfDay) {
            this.time = this.getReadableTime(timeOfDay)
        },
        readableDate() {
            if (this.date != '') {
                return this.date.toDateString()
            } else {
                return this.date
            }
            
        },
        getSchedule() {
            var currentDate = new Date();
            var nextMonth = new Date(currentDate)
            nextMonth.setDate(nextMonth.getDate() + 31)
            var year = nextMonth.getFullYear();
            var month = nextMonth.getMonth();
            var day = nextMonth.getDate();
            if (this.selected_therapist === 'Reena') {   
                this.is_loading = true;
                this.disabledDates = { 
                    from: new Date(year, month, day),
                    to: new Date(), 
                    days: [6, 0]
                 }
                var spreadSheetId = '14dCZLrMeeu16har8l4DWh3FcMbCsU9WauOjxl-ePxO4'
                var url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}/?key=AIzaSyB_WhBZMpaA0KiWpv_bo7Vp0USxAoQgKeY&includeGridData=true`;   
                this.$http.get(url).then((response) => {
                    this.api_data = response.data.sheets[0].data[0].rowData
                    this.parseSpreadsheetData();
                    
                })
            }

            // if (this.selected_therapist === 'Keegan') {
            //     // console.log(`${selected_therapist}`)
            //     this.is_loading = true;
            //     this.disabledDates = { to: new Date(), days: [6, 0, 1] }  
            //     var spreadSheetId = '131OHVFSWzTyKA0WZ-uSOYFjtmPJXt3hqzrYv5nyKyhM'
            //     var url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}/?key=AIzaSyB_WhBZMpaA0KiWpv_bo7Vp0USxAoQgKeY&includeGridData=true`;   
            //     this.$http.get(url).then((response) => {
            //         this.api_data = response.data.sheets[0].data[0].rowData
            //         this.parseSpreadsheetData();
                    
            //     })                
            // }
        },
        parseSpreadsheetData() {
            var spreadsheetData = this.api_data
            // console.log(`THIS IS THE API DATA: ${spreadsheetData}`)
            const days_array = []
            const times_array = []
            const finalArray = []
            for (let i = 0; i < spreadsheetData.length; i++) {
                var dailyData = spreadsheetData[i].values
                days_array.push(dailyData[0].userEnteredValue.stringValue)
                var dailyDataParsed = {}
                var dailyDataTimes = []
                for (let i = 0; i < dailyData.length; i++) {
                    if (i === 0) {
                        dailyDataParsed.dayOfWeek = dailyData[i].userEnteredValue.stringValue
                    } else {
                        dailyDataTimes.push(dailyData[i].userEnteredValue.stringValue)
                    }
                    dailyDataParsed.times = dailyDataTimes
                }
                times_array.push(dailyDataParsed)
                
            }
            this.times_open = times_array
            this.convertTimesToArray();
        },

        getAvailableTimes() {
            var availableTimes = []
            const dayOfWeek = (String(this.date).substring(0,3))
            const allAvailableTimes = this.times_open
            for (let i = 0; i < allAvailableTimes.length; i++) {
                if (String(allAvailableTimes[i].dayOfWeek).substring(0,3) === dayOfWeek) {
                    availableTimes.push(allAvailableTimes[i].times)
                }
            }
        },

        parseAvailableTimes(available_times) {
            this.parsed_available_times = available_times[0]
            const parsedAvailableTimes = available_times[0]
            const startAndEndTimes = []
            const timesTo24HourIntegers = []
            const finalTimesArray = []
           
            for (let i = 0; i < parsedAvailableTimes.length; i++) {
                startAndEndTimes.push(parsedAvailableTimes[i].split("-"));
            }

            for (let i = 0; i < startAndEndTimes.length; i++) {

                const period = {}

                period.start = this.getTwentyFourHourTime(startAndEndTimes[i][0])

                period.end = this.getTwentyFourHourTime(startAndEndTimes[i][1])

                timesTo24HourIntegers.push(period)

            }

        },
        getTwentyFourHourTime(amPmString) {
            var d = new Date("1/1/2013 " + amPmString); 
            return Number(d.getHours()); 
        },
        availableTreatments() {
            const availableTreatments = []
            for (let i = 0; i < this.treatments.length; i++) {
                if (this.treatments[i].availability === 'Book now') {
                    availableTreatments.push(this.treatments[i])
                }
            }

            return availableTreatments
        },
        sendEmail() {
            if (this.first_name != '' && this.last_name != '' && this.validEmail() && this.mobile_phone != '' && this.date != '' && this.selected_duration.length > 0 && this.time != '') {
                this.sending = true
                emailjs.sendForm('service_zvdhcxe', 'template_yvujnbr', this.$refs.form, 'nwN1lD6JxHEotXGsF')
                .then((result) => {
                    this.sending = false 
                    this.sent_successfully = true
                    this.email = ''
                    this.message = ''
                    this.first_name = ''
                    this.last_name = ''
                    this.selected_therapist = ''
                    this.error = ''
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
            }
        },
    },
    computed: {   
        filteredTimes() {
            const times = this.final_times_for_real
            const filteredTimes = []
            for (let i = 0; i < times.length; i++) {
                if (!this.getConflictingTimes(times[i]).length > 0) {
                    // console.log(this.getConflictingTimes(times[i]))
                    filteredTimes.push(times[i])
                }   
            }
            return filteredTimes
        }

    },
    watch: {
        date() {
            this.time = ''
            this.getSchedule();
            this.convertTimesToArray();
            this.componentKey++
        },
        selected_duration() {
            this.time = ''
            this.getSchedule();
            this.convertTimesToArray();
            this.componentKey++
        },
        selected_therapist() {
            this.api_data = [];
            this.selected_duration = [];
            this.final_times_unavailable_array = [];
            this.time = '';
            this.date = '';
        }
    }
}


</script>

<style>

@media(max-width: 600px) {

    #app .vdp-datepicker__calendar {
        width: 100%; 
    }

}




</style>

<style scoped>

#booking-form {
    background-color: white;
    box-sizing: border-box;
    padding: 25px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
}

.selector-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.duration-selector {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.duration {
    width: 45%;
    box-sizing: border-box;
    padding: 20px 5px 20px 5px;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px 10px 0px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.duration:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.treatment {
    display: flex;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    color: gray;
    border: 1px solid rgba(0, 0, 0, 0);
    border: 1px solid lightgray;
    flex-grow: 1;
}

.treatment:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.duration-time {
    font-size: 15px;
    font-weight: 600;
    margin: 2.5px 0px 2.5px 0px;
    color: gray;
}

.duration-price {
    font-size: 15px;
    margin: 2.5px 0px 2.5px 0px;
    color: gray;
}

.hidden {
    display: none;
}

.datepicker-container {
    cursor: pointer;
}

.show-all {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.show-all p {
    margin: 5px 0px 5px 0px;
    font-size: 12.5px;
    cursor: pointer;
    color: gray;
}

.not-available {
    display: none !important;
}

.datepicker {
    cursor: pointer;
}

.time-selector {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
}

.time {
    width: 45%;
    box-sizing: border-box;
    padding: 20px 5px 20px 5px;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px 10px 0px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;   
}

.unavailable {
    pointer-events: none;
    opacity: 0.5;
}

.selected {
    background-color: #61B167;
    color: white !important;
    border: 1px solid #61B167;
    font-weight: 600;
}

.invisible {
    visibility: hidden;
    height: 0px !important;
    margin: 0px 0px 0px 0px;
    position: absolute;
    width: 50px;
}

.selected p {
    color: white;
}

.calendar-wrapper {
    border: 10px solid black;
}

.submitted h1 {
    color: #0F7446;
    font-weight: 800;
}

.modal-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.confirmation {
    /* color: gray; */
    font-weight: 600;
    color: gray;
}

.note {
    color: gray;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: center;
    box-sizing: border-box;
    padding: 0px 10% 0px 10%;
}

.error-container {
    height: 40px;
    margin-bottom: 10px;
}

.unavailable-text {
    font-family: 'Cormorant Garamond', serif;
    color: #A9444B;
}

.unavailable-text-bold {
    font-weight: 600;
}

/deep/ .vdp-datepicker__calendar{
    border: none !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.unavailable-temp {
    color: lightcoral;
    font-size: 10px;
}

@media(max-width: 750px) {
    #booking-form {
        border-radius: 0px;
    }
}


@media(max-width: 600px) {
    .selector-container {
        width: 100%;
    }
}


</style>