<template>

    <div id="contact">

        <h1 class="light-green-text">Contact</h1>

        <div class="content">

            <div class="contact-options">

                <div class="option gray-text">

                    <img src="@/assets/icons/RJMT_telephone.svg" />

                    <p>(989) 948-0368</p>

                </div>

                <div class="option gray-text">

                    <img src="@/assets/icons/RJMT_location_green.svg" />

                    <p>Midland, MI 48642</p>

                </div>

                <a class="option gray-text" href="mailto:book@rjmt.care">

                    <img src="@/assets/icons/RJMT_email.svg" />

                    <p>book@rjmt.care</p>

                </a>

                <a class="option gray-text" href="https://www.instagram.com/rjmt.care">

                    <img src="@/assets/icons/RJMT_insta.svg" />

                    <p>rjmt.care</p>

                </a>

                <a class="option gray-text" href="https://www.facebook.com/rjmt.care">

                    <img src="@/assets/icons/RJMT_fb.svg" />

                    <p>Reena’s Jade Massage Therapy</p>

                </a>

                <ContactForm />

            </div>

            <div class="map">

                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92420.42863978684!2d-84.23620255!3d43.6244784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8821809c03db0717%3A0x7a6c83e422b20a90!2sMidland%2C%20MI%2C%20USA!5e0!3m2!1sen!2sid!4v1661147498710!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92420.42863978684!2d-84.23620255!3d43.6244784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8821809c03db0717%3A0x7a6c83e422b20a90!2sMidland%2C%20MI%2C%20USA!5e0!3m2!1sen!2sid!4v1661147498710!5m2!1sen!2sid" width="100%" height="667.438" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

            </div>



        </div>

    </div>



</template>


<script>
import ContactForm from '@/components/ContactForm.vue'

export default {
    name: 'Contact',
    components: {
        ContactForm
    }
}


</script>

<style scoped>

#contact {
    width: 100%;
    max-width: 1200px;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    margin: auto;
    padding-bottom: 100px;
}

#contact h1 {
    font-family: 'Cormorant Garamond', serif;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 1px;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.content a {
    text-decoration: none;
}

.contact-options {
    width: 40%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 20px 0px 0px;
}

.map {
    width: 60%;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 10px 0px;
}

.option p {
    margin: 0px 0px 0px 0px;
}

.option img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

@media(max-width: 1000px) {
    .map {
        width: 100%;
    }

    .contact-options {
        width: 100%;
        margin-bottom: 70px;
        padding: 0px 0px 0px 0px;
    }
}

</style>