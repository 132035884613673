

const state = () => ({
    treatment: {}
})


const mutations = {
    setTreatment (state, treatment) {
        state.treatment = treatment
    }
}


const getters = {
    getTreatment (state) {
        return state.treatment
    }
}

export default {
    state,
    getters,
    mutations
}