const treatments = [
    {
        name: 'Swedish Massage',
        combo: false,
        description: ['Swedish massage is a popular type of massage therapy suitable for general relaxation and tension release. This modality is ideal for clients who want stress reduction, increased energy, and simply want to relax.'],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$80'
            },
            { 
                duration: '90 mins.',
                price: '$110'
            }
        ]
    },
    { 
        name: 'Deep Tissue Massage',
        combo: false,
        description: ['Deep tissue massage targets deep layers of muscle and the surrounding tissues. Deep tissue massage is an intensive way to reduce muscle pain, assist in recovery after an injury, and increase flexibility.'],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$90'
            },
            { 
                duration: '90 mins.',
                price: '$120'
            }
        ]
    },
    { 
        name: 'Foot Massage (Reflexology)',
        combo: false,
        description: ["Good health begins in the feet and travels up to the entire body. Reflexology massages zones of the feet that correspond to different organs of the body. It starts with the foot then works up the calf. It rejuvenates all parts of the body, helping with issues such as headaches, poor circulation, sleep disorders, digestive problems and pain relief."],
        availability: 'Book now',
        durations: [
            { 
                duration: '30 mins.',
                price: '$40'
            },
            { 
                duration: '60 mins.',
                price: '$80'
            }
        ]
    },   
    { 
        name: 'Chair Massage',
        combo: false,
        description: ["This is a type of massage that takes place on a special massage chair. It is an ideal pampering treatment for those on a busy schedule. You remain fully clothed throughout a treatment of 30 minutes or less. A chair massage will leave you with a relaxed mellow feeling."],
        availability: 'Book now',
        durations: [
            { 
                duration: '15 mins.',
                price: '$30'
            },
            { 
                duration: '20 mins.',
                price: '$40'
            }
            // { 
            //     duration: '30 mins.',
            //     price: '$35'
            // }                       
        ]
    },           
    { 
        name: 'Traditional Chinese Acupressure Massage (Tui Na)',
        combo: false,
        description: ["Acupressure massage uses hands, elbows, and different devices to apply pressure to specific pressure points that are case dependent. This is typically done over a fully clothed client. Benefits include stress reduction and reduced muscle pain."],
        availability: 'Book now',
        durations: [
            { 
                duration: '30 mins.',
                price: '$40'
            },
            { 
                duration: '60 mins.',
                price: '$80'
            }                 
        ]                    
    },    
    { 
        name: 'Ashiatsu Deepfeet Bar Therapy',
        combo: false,
        description: ["Ashiatsu (meaning foot pressure) DeepFeet Bar Therapy is a barefoot massage technique. The therapist delivers deep, broad, consistent pressure while utilizing their feet and body weight. The benefits of Ashiatsu massage are longer lasting than most other modalities. This technique helps improve range of motion, relieves muscle tension, increases circulation, reduces stress, and a greater sense of well-being."],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$95'
            },
            { 
                duration: '90 mins.',
                price: '$130'
            }                      
        ]
    },
    { 
        name: 'Couples Massage',
        combo: false,
        description: ["This is an ideal way to share your favorite massage modality with a family member or friend. Two people receive the benefits of massage in the same room at the same time. Modalities can be customized to each client’s needs."],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$170'
            },
            { 
                duration: '90 mins.',
                price: '$220'
            }                      
        ]
    },
    { 
        name: 'Hot Stone Massage',
        combo: false,
        description: ["Ideal for clients who want deep relaxation and calmness. Hot Stone Massage uses the direct heat of special stones. Your therapist manipulates these smooth stones to apply warm pressure to relax muscles while getting to deep muscle layers. The use of hot stones can be integrated with several different modalities."],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$90'
            },
            { 
                duration: '90 mins.',
                price: '$120'
            }                      
        ]
    },
    { 
        name: 'Clinical Massage',
        combo: false,
        description: ["Clinical Massage is a manual therapy. It focuses on medical issues and pain relief. It involves thorough assessment and evaluation before treatment. The goal is to help the client to achieve and improve specific needs."],
        availability: 'Book now',
        durations: [
            { 
                duration: '60 mins.',
                price: '$120'
            }
        ]
    },
    {
        name: 'Other Modalities Available Upon Request:',
        combo: false,
        description: ["Rain Drop Therapy", "Cranial Sacral Therapy", "Lymphatic Drain Therapy"],
        availability: 'Inquire',
        durations: []
    }
    // {
    //     name: 'Combo 1: Swedish Massage + Head Massage + Reflexology',
    //     combo: true,
    //     description: '',
    //     availability: 'Book now',
    //     durations: [
    //         { 
    //             duration: '90 mins.',
    //             price: '$100'
    //         },
    //         { 
    //             duration: '120 mins.',
    //             price: '$130'
    //         }                      
    //     ]
    // },
    // {
    //     name: 'Combo 2: Deep Tissue Massage + Head Massage + Reflexology',
    //     combo: true,
    //     description: '',
    //     availability: 'Book now',
    //     durations: [
    //         { 
    //             duration: '90 mins.',
    //             price: '$110'
    //         },
    //         { 
    //             duration: '120 mins.',
    //             price: '$150'
    //         }                      
    //     ]
    // },
    // {
    //     name: 'Combo 3: Barefoot Massage + Head Massage + Reflexology',
    //     combo: true,
    //     description: '',
    //     availability: 'Book now',
    //     durations: [
    //         { 
    //             duration: '90 mins.',
    //             price: '$110'
    //         },
    //         { 
    //             duration: '120 mins.',
    //             price: '$150'
    //         }                      
    //     ]
    // }
]

export { treatments }