import Vue from 'vue'
import Vuex from 'vuex'
import treatment from './modules/treatment'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    treatment: treatment
  }
})
