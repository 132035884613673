<template>

  <div id="vagaro-button" @click="redirectToVagaro()">

    Book on Vagaro

  <!-- <div id='frameTitle' class='embedded-widget-title' style='font-size: 23px; color: #333;font-family:Arial, Helvetica, sans-serif; line-height:24px; padding: 18px 10px 8px; text-align: center; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;'>
  </div> -->
  
    <!-- <div class="vagaro" style="width:250px; padding:0; border:0; margin:0 auto; text-align:center;">
      <a href="https://sales.vagaro.com/">Powered by Vagaro</a>&nbsp;<a href="https://sales.vagaro.com/salon-software">Salon Software</a>,&nbsp;<a href="https://sales.vagaro.com/spa-software">Spa Software</a>&nbsp;&amp;&nbsp;<a href="https://sales.vagaro.com/fitness-software">Fitness Software</a>
    </div>

    <div class="vagaro"></div> -->

  </div>




</template>

<script>

export default {
  name: "VagaroScript",
  mounted() {
    // this.loadScript();
  },
  methods: {
    loadScript() {
      let vagaroScript = document.createElement('script')
      vagaroScript.setAttribute('src', 'https://www.vagaro.com//resources/WidgetPopupLoader/OZqoDJGmEJGcT3qmV35y6JuPlXiz3avV34mC2PlFK9lRsiWJcyxapkUcvCu7gevEhAJDXwOapcUbfY?v=38v67oDokwRZNsVkNgrIoD5GwrVU1TWlUtxkXInCk14#')
      document.querySelector('.vagaro').append(vagaroScript);
    },
    redirectToVagaro() {
      window.location.replace("https://www.vagaro.com/rjmt/services")
    }
  }
}



</script>

<style scoped>

.vagaro a {
  font-size:14px; color:#AAA; 
  text-decoration:none;
}

#vagaro-button {
  background-color: #DC6E68;
  color: white;
  width: 250px;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 100px;
}



</style>