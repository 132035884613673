import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import { treatments } from './treatments/treatments.js'
import { schedule } from './schedule/schedule.js'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueAxios, axios)

// Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB_WhBZMpaA0KiWpv_bo7Vp0USxAoQgKeY',
    libraries: 'places'
  }
})

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.use(VueMoment, {
  moment
})


const treatmentsPlugin = {
  install() {
    Vue.treatments = treatments
    Vue.prototype.$treatments = treatments
  }
}

const schedulePlugin = {
  install() {
    Vue.schedule = schedule
    Vue.prototype.$schedule = schedule
  }
}

// plugins for treatments and scheduling
Vue.use(treatmentsPlugin)
Vue.use(schedulePlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
